<template>
    <vue-cal :selected-date="fecha_inicial"
             :time-from="7 * 60"
             :time-to="19 * 60"
             :disable-views="['years', 'year', 'month']"
             hide-weekends
             :twelveHour="twelveHour"
             timeFormat="h:mm{am}"
             :timeCellHeight="cell_height"
             :min-event-width="minWodth"
             :hide-weekdays="[1, 2]"
             locale="es"
             :events="events">
    </vue-cal>
</template>

<script>
    import VueCal from 'vue-cal'
    import 'vue-cal/dist/i18n/es.js'
    import 'vue-cal/dist/vuecal.css'
    export default {
        name: "cta-calendar",
        components: {
            VueCal
        },
        data: () => ({
            twelveHour: true,
            minWodth: 0
        }),
        props: ["events", "fecha_inicial", "cell_height"],
    }
</script>

<style>
    .vuecal__event-title { font-weight: bold; font-size: 13px }
    .vuecal__event-time { font-size: 12px; }
    .vuecal__title-bar { font-size: 18px; background-color: #e4f5ef;}
    .vuecal__menu { font-size: 16px; color: white; background-color: var(--theme-color);}
    .vuecal__event { font-size: 12px;}
    .vuecal__no-event {display: none;}

    .vuecal__event.taller {background-color: rgba(253, 156, 66, 0.85);border: 1px solid rgb(233, 136, 46);color: #fff;}
    .vuecal__event.inaguracion {background-color: rgba(164, 230, 210, 0.9);border: 1px solid rgb(144, 210, 190);}
    .vuecal__event.magistral {background-color: rgba(255, 102, 102, 0.85);border: 1px solid rgb(235, 82, 82);color: #fff;}
    .vuecal__event.ponencias {background-color: rgba(255, 58, 143, 0.7);border: 1px solid rgb(235, 38, 123);color: #fff;}
    .vuecal__event.panel {background-color: rgba(100, 200, 255, 0.8);border: 1px solid rgb(80, 180, 235);color: #fff;}
    .vuecal__event.clausura {background-color: rgba(255, 200, 90, 0.75);border: 1px solid #ffc356;}
    .vuecal__event.lunch {
        background: repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px);
        color: #999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .vuecal__event.lunch .vuecal__event-time {display: none;align-items: center;}
</style>
